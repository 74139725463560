import { IncomingMessage } from 'http';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';

import useLinkProps, { originalRoutes } from '../../../hooks/use-link-props';
import { fetchShippingLocations } from '../../../services/shipping-locations/list';
import { setShippingLocation } from '../../../services/shipping-locations/set';
import { navigationOpen } from '../../../state/navigation';
import { redirectToNewShippingLocation } from '../../../utils/set-new-shipping-location';
import BPImage from '../../ui/image';
import Select from '../../ui/inputs/selectv2';
import ItemWithImage from '../../ui/inputs/selectv2/item-with-image';
import { useAppContext } from '../../providers/app-context';

type ShippingSelectorProps = {
  preselected?: string;
};

export const loadShippingLocations = async (
  { queryKey: [, , locale, shippingLocation] },
  req?: IncomingMessage
) => {
  const data = await fetchShippingLocations({ locale }, req);

  const dataWithIcon = data.map((e) => ({
    icon: `${process.env.PUBLIC_PREFIX}/svg/icons/flags/${
      e.country_code.toLowerCase() != 'gb'
        ? e.country_code.toLowerCase()
        : e.region_code.toLowerCase()
    }.svg`,
    country_code: e.country_code,
    region_code: e.region_code,
    value: e.id,
    label: e.name,
    selected: e.selected,
  }));

  return dataWithIcon;
};

const ShippingSelector = ({ preselected }: ShippingSelectorProps) => {
  const router = useRouter();
  const { locale = 'en', pathname, push, query } = router;
  const { t } = useTranslation();
  const client = useQueryClient();
  const getLink = useLinkProps();
  const [, setOpen] = useAtom(navigationOpen);
  const [error, setError] = useState<Array<string>>([]);
  const { isBparts } = useAppContext();

  const { data: shippingLocations, isLoading: isLoadingShippingLocations } =
    useQuery(
      ['user-dependent', 'shipping-locations-list', locale],
      loadShippingLocations
    );

  const { mutate, isLoading: isMutating } = useMutation(
    ({
      locale,
      shipping_location,
    }: {
      locale: string;
      shipping_location: string;
    }) =>
      setShippingLocation({
        locale,
        shipping_location,
        csrftoken: Cookies.get('csrftoken') || '',
      }),
    {
      onSuccess: (_, variables) => {
        if (
          Cookies.get('isUKviewer') &&
          Cookies.get('isUKviewer') !== 'false'
        ) {
          Cookies.set('isUKviewer', 'false');
        }

        if (
          Cookies.get('isGlobalViewer') &&
          Cookies.get('isGlobalViewer') !== 'false'
        ) {
          Cookies.set('isGlobalViewer', 'false');
        }

        redirectToNewShippingLocation({
          locations: shippingLocations!,
          newShippingLocation: variables.shipping_location,
          router,
          isBparts,
        });
      },
      onError: (error) => {
        const errorMessages: Array<string> = [];

        Object.keys(error).map((key) =>
          errorMessages.push(`${key}: ${error[key]}`)
        );

        setError(errorMessages);
      },
    }
  );

  const currentLocation = useMemo(() => {
    let currentLocation = shippingLocations?.find((sl) => sl.selected);

    if (preselected && shippingLocations) {
      currentLocation = shippingLocations?.find(
        (sl) => sl.country_code === preselected
      );
    }

    return currentLocation;
  }, [shippingLocations, preselected]);

  return (
    <Select
      id="shipping-location-selector"
      element={currentLocation && currentLocation?.label}
      icon={
        currentLocation && (
          <div className="relative h-6 rounded-full shadow-md">
            <BPImage
              src={currentLocation.icon}
              width={24}
              height={24}
              alt={currentLocation.label}
            />
          </div>
        )
      }
      onChange={(value: string) => mutate({ locale, shipping_location: value })}
      value={currentLocation?.value}
      options={shippingLocations?.map((locale) => ({
        id: locale.value.toString(),
        value: locale.value,
        element: <ItemWithImage {...locale} />,
      }))}
      disabled={isLoadingShippingLocations || isMutating}
      label={t('header:links.country-delivery')}
      menuPlacement="top"
      dataCy="shipping-selector"
    />
  );
};

export default ShippingSelector;
